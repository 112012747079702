import React from 'react';
import { Link } from 'gatsby';
import ydsLogo from '../../images/logos/yds-logo.jpg';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className='top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow'>
        <div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <Link
              to='/'
              className='text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap'
            >
              <img
                src={ydsLogo}
                alt='YDS Chicago Logo'
                style={{ width: '140px' }}
              />
            </Link>
            <button
              className='cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className='fas fa-bars' />
            </button>
          </div>
          <div
            className={`lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none${
              navbarOpen ? ' block' : ' hidden'
            }`}
            id='example-navbar-warning'
          >
            <ul className='flex flex-col lg:flex-row list-none lg:ml-auto'>
              <li className='flex items-center'>
                <Link
                  to='/about'
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  activeStyle={{
                    color: '#ed8936',
                    fontWeight: 700,
                  }}
                >
                  About Us
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/upasana'
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  activeStyle={{
                    color: '#ed8936',
                    fontWeight: 700,
                  }}
                >
                  Upasana
                </Link>
              </li>
              {/* <li className='flex items-center'>
                <Link
                  to='/events'
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  activeStyle={{
                    color: '#ed8936',
                    fontWeight: 700,
                  }}
                >
                  Events
                </Link>
              </li> */}
              <li className='flex items-center'>
                <Link
                  to='/gallery'
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  activeStyle={{
                    color: '#ed8936',
                    fontWeight: 700,
                  }}
                >
                  Gallery
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/contact'
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  activeStyle={{
                    color: '#ed8936',
                    fontWeight: 700,
                  }}
                >
                  Contact
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  href='https://www.facebook.com/YDSChicago/'
                  target='_blank'
                >
                  <i className='text-gray-500 fab fa-facebook text-lg leading-lg' />
                  <span className='lg:hidden inline-block ml-2'>
                    YDS Chicago Facebook Page
                  </span>
                </Link>
              </li>
              <li className='flex items-center'>
                <a
                  className='hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center font-semibold'
                  href='https://www.youtube.com/c/HaridhamChicago/videos'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='text-gray-500 fab fa-youtube text-lg leading-lg ' />
                  <span className='lg:hidden inline-block ml-2'>
                    YDS Chicago Youtube Channel
                  </span>
                </a>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/donate'
                  className='bg-orange-500 text-white active:bg-orange-600 font-semibold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                >
                  Donate
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
